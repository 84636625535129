import React from "react";
import Diagnosis from "../components/forms/Diagnosis";

function Form() {
  return (
    <>
      <Diagnosis />
    </>
  );
};

export default Form;