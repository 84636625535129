import React from "react";
import Results from "../components/forms/Results";

function CheckResults() {
  return (
    <>
      <Results />
    </>
  );
};

export default CheckResults;